import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const FlexibleScheduling = () => {
	return (
		<>

		<MetaData title="Flexible Scheduling" />
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Flexible Scheduling</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


		
		<div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Flexible Scheduling</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Flex-time means you do not have to disrupt your life for our tutorials to blend with your life rhythm. We however acknowledge the fact that students have different schedules and hence commitments based on the expectations of the course. This leads our goal of providing easy session timetables for the students such that they can manage their schools, activities as well as other work without stress.
</p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Customised timetable options</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We have flexible learning schedules to meet each student's schedule needs. Regarding the daily schedule, we can arrange our timetable for morning, afternoon or evening, which is more suitable for you. It is beneficial for students to enable them to have schedules for learning so that they can still attend to other issues effectively.
</p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Ease of rescheduling</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            It is for this reason that we understand that such plans have to take into consideration that life is full of changes and uncertainties. In terms of operational flexibility, we have the possibility to adjust the scheduled sessions if necessary. This means that if something comes up in the course of the day or week, you do not have to worry about being locked into a schedule where you cannot move when you are a tutor. In this way, we have the primary objective of allowing the students to have maximum comfort in their learning process.
</p>

        <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Enhanced learning experience</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We ensure that the tutoring is done at the most preferred time by the tutor so that learners have minimised stress and maximised attention during study. In this case students' schedules are not clashing and therefore the student has proper time to focus on his/her studies. It also helps in achieving the objectives of the tutoring so that the students get the best out of the tutoring sessions to improve on their grades.
            </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Supporting your goals</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            One of our key strategies therefore is the ability to create a flexible timetable that will enhance students'achievement of their academic objectives. We develop a schedule that will be comfortable for students to follow, thus enhancing ones skill in studying.
</p>

        </div>
      </div>
      </div>
  	
		</>
	)
}

export default FlexibleScheduling	