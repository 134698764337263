import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const PersonalisedLearningPlan = () => {
	return (
		<>

		<MetaData title="Personalised Learning Plans" />
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Personalised Learning Plans</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


		
		<div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Personalised Learning Plans</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            The core of learning at our centre is that each student is unique and therefore, develops the Personalised Learning Plans (PLPs). They will also secure each student to enable the achievers to get the support they need in their learning process.
            </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Individual assessment</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            The first step, therefore, involves a diagnostic test to determine the stock that a given student has in terms of their learning, abilities, and deficits. This kind of assessment enables us to learn more, particularly about how the students learn and their academic strengths and challenges. Therefore, after gaining knowledge of their goals, we come up with a unique strategic plan.
</p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Tailored learning goals</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            This is why our Personalised Learning Plans include realistic and measurable objectives that relate to each learner's learning profile and potential. This means that we were to establish achievable goals and objectives to measure the outcome while making the learning process relevant and productive.
            </p>

        <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Flexible strategies</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Our teaching approaches depend on the student's learning style and their progress. Regardless of whether a student is helped by visual aids, hands-on activities, or one-on-one tutoring our plans are adaptable for such intentions. This guarantees that every student is beneficiary of the kind of support they need according to their mode of learning.
            </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Regular reviews</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            To make sure that the Personalised Learning Plan continues to be beneficial to the student, there are constant checks and balances on the student's performance. Such reports help to make the necessary corrections to the plan and receive continuous feedback. This dynamic approach makes students to go on making progress and to remain motivated always.
            </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Support and guidance</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Support from the tutors is always present in the learning process for the whole period. We support students when receiving difficulties and when they succeed. The main aim here is to help the students to build confidence and develop personalised study skills for success.
Regarding the services offered at KBM Tutors, Personalised Learning Plans aim to offer customised educational services to meet each student's needs and help her/him be the best.
</p>


        </div>
      </div>
      </div>
  	
		</>
	)
}

export default PersonalisedLearningPlan	