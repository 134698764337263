import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const TutorsQualification = () => {
	return (
		<>

		<MetaData title="Tutors Qualifications" />
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Tutors Qualifications</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


		
		<div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Tutors Qualifications</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            On the people around us, at KBM Tutors, we pride ourselves in having numerous unique, qualified and experienced tutors. Our personnel is chosen in compliance with criteria of high qualifications, relevant experience and passion to education to provide students the best service. The team at KBM Tutors comprises competent, willing, and committed personnel who are dedicated to providing quality education.
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Highly qualified professionals</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            All our tutors have respective academic qualifications in terms of degrees and professional certifications in their fields. All of them are educated, and many of them are postgraduates. They are certified professionals in their own fields, which in and of itself is an indication of the level of their insight and an ability to understand and apply knowledge. This will for sure ensure that they offer the best standards of educational assistance.
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Experience in education</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Experience plays a vital base in teaching. Our tutors employ many years of experience when it comes to handling students at different levels. They use adequate experience in an effective teaching method. At the same time, consider all the possible changes that would be necessary to satisfy the needs for which everyone needs.
</p>

        <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Training and development</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            All our tutors attend seminar sessions, training, and workshops wherever possible and therefore guarantee that they are conversant with the current teaching techniques, and other matters concerning education. This continuous learning helps them in extending effective support to the students.
</p>

<div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Dedicated to teaching</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Not only are our tutors skilled professionals, they also have a special consideration for the part of being a tutor. This passion and dedication develops a favourable learning climate that encourages student learning and at the same time passion the students. This makes learning to be very fun and enjoyable.
</p>

<div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Personalised support</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            All our tutors assist in a way that caters to each student's unique needs. Another way all the students achieve confidence, enhanced skills, and excellent results is by paying attention to each student's learning style and academic objectives.
</p>


        </div>
      </div>
      </div>
  	
		</>
	)
}

export default TutorsQualification	