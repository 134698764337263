import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const Ethos = () => {

	return (
		<>
  <MetaData title='Ethos' />
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Ethos</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


      <div className="untree_co-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Ethos</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            KBM Tutors' success comes from our special teaching methods across London. Our tutoring stands out from others by challenging students based on their knowledge and performance rather than their age, supporting individualised growth. KBM's success is driven by the diverse range of subjects we offer. We also have access to exceptional teaching resources, including state-of-the-art technology, comprehensive learning materials, and a wealth of online tools.
            </p>
            <p data-aos="fade-up" data-aos-delay="100">
              Since our founding, KBM Tutors has created an engaging and welcoming atmosphere. It helps our students achieve maximum academic achievement while building confidence, self-esteem, and essential interpersonal skills. We are fully committed to this holistic educational mission, which has been central to KBM Tutors from the very beginning.
            </p>
            <p data-aos="fade-up" data-aos-delay="100">
              Our dedication to our mission is evident in our quality, personalised support, and continuous growth as a trusted educational partner, as outlined below:
            </p>

        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mb-5 mb-lg-0" data-aos="fade-up" data-aos-delay="0">

          <figure className="">
                <img src="images/Ethos.png" alt="Image" className="img-fluid" />
              </figure>

        </div>
        </div>
        <div className="col-lg-12">
          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li>
              KBM Tutors is committed to quality, dedication, and innovation, reflecting the values supported by our decades of contribution to education.
            </li>
            <li>
              Our tutors use deep knowledge and understanding of educational needs to design individualised tutoring programmes for each student.
            </li>
            <li>
              From improving basic subjects to exam preparation and advancing study skills, our tutors provide full support to empower students to excel in their studies.
            </li>
            <li>
              Our approach is built on a natural passion for education. We strive to create an environment that nurtures every student's potential.
            </li>
            <li>
              As we continue to grow and expand, KBM Tutors remains committed to building on the success and positive reputation we have developed as a trusted educational partner.
            </li>
          </ul>


        </div>
      </div>
      </div>
  


		</>
	)
}

export default Ethos