import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const Vision = () => {

	return (
		<>
  <MetaData title='Mission & Vision'/>
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/3.png')` }}>
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-12">
          <div className="row justify-content-center ">
            <div className="col-lg-6 text-center ">
              <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Mission & Vision</h1>
              {/*<div className="mb-5 text-white desc mx-auto" data-aos="fade-up" data-aos-delay="200">
                <p>Another free template by Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live.</p>
              </div>

              <p className="mb-0" data-aos="fade-up" data-aos-delay="300"><Link to="#" className="btn btn-secondary">Explore courses</Link></p>*/}

            </div>


          </div>

        </div>

      </div> 
    </div> 

  </div> 



  <div className="services-section">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-lg-5 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Mission</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">KBM Tutors is committed to providing personalised tutoring services that fulfil the unique needs of each student. We aim to empower students with excellent customised tutoring services. It will enhance their academic excellence and personal growth. KBM has a supportive and engaging learning environment. This atmosphere develops confidence and improves students' understanding, unlocking their full potential. We ensure a rewarding learning experience for all our students through tailored instruction. Our innovative teaching methods further enhance this experience.</p>

          <p data-aos="fade-up" data-aos-delay="300"><Link to="#" className="btn btn-primary">Get Started</Link></p>

        </div>
        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="0">
          <figure className="img-wrap-2">
            <img src="images/teacher-min.jpg" alt="Image" className="img-fluid"/>
            <div className="dotted"></div>
          </figure>

        </div>
      </div>
    </div>
  </div>

 <div className="services-section">
    <div className="container">
      <div className="row justify-content-between">
      <div className="col-lg-6" data-aos="fade-up" data-aos-delay="0">
          <figure className="">
            <img src="images/2.png" alt="Image" className="img-fluid"/>
            <div className="dotted"></div>
          </figure>

        </div>
        <div className="col-lg-6 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Vision</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">Our vision at KBM Tutors is to be a lead provider of high-quality, personalised tutoring. It will transform education by inspiring a lifelong passion for learning. We envision a future where every student, with the support of their parents and educators, has the knowledge, skills, and confidence to succeed academically and personally, regardless of the starting point. We will always look to evolve our approaches and integrate cutting-edge educational practices in our work. It will make a difference in each educational journey throughout the various stages of academic life.</p>

          <p data-aos="fade-up" data-aos-delay="300"><Link to="#" className="btn btn-primary">Get Started</Link></p>

        </div>
      </div>
    </div>
  </div>

		</>
	)
}

export default Vision