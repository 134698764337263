import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const ProgressTracking = () => {
	return (
		<>

		<MetaData title="Progress Tracking" />
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Progress Tracking</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


		
		<div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Progress Tracking</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Progress monitoring is an integral part of our tutoring method. It ensures that students achieve one milestone after another and reach their set goals as estimated. We fully believe that regular monitoring, together with feedback, forms the foundation of learning. Our system monitors progress over time for each student to identify strengths and areas that need attention.
            </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Regular assessments and feedback</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We regularly assess the pupil in order to measure their understanding and progress. This follows with detailed feedback, pinpointing areas of success and advising on areas where further improvements can be made. This frequent evaluation enables us to make specific changes in our approach for each child and gets them on the right track.
</p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Clear communication</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We have opened lines of communication regarding progress with both students and parents. In this regard, detailed reports and updates shall be made to keep all parties concerned abreast of the same as pertains to academic development. Such transparency in process allows parents to be consistently supportive and engaged, while students can easily identify achievements and areas for growth.
</p>

        <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Goal setting and achievement</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We ensure to set achievable and realistic goals for all our students based on progress tracking. These are set according to their needs for learning and attaining academic goals. Through regular review of the goals set, we are able to change the approaches in teaching and thus challenge and motivate the students further.
            </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Continuous improvement encouragement</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Success tracking encourages progress because of our focus. We track progress on a regular basis and modify the approach to help our students sustain their motivational level towards learning. This is an assurance that it commits to student success through progress tracking, full potential accomplishment, and the realisation of educational goals.
            </p>

        </div>
      </div>
      </div>
  	
		</>
	)
}

export default ProgressTracking	