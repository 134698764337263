import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const TeachingMethod = () => {
	return (
		<>

		<MetaData title="Teaching Methods" />
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Teaching Methods</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


		
		<div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Teaching Methods</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            The process of teaching and learning at KBM Tutors is as constructive as it is creative. We emphasis on the fact that the methods used depend on each learner. We pay much attention to the process of building a positive learning process that will enable the students to perform according to their potential.
</p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Interactive learning</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We include extended instructions that involve the use of teaching aids. This comes in form of group or class discussions, participation in activities as well as supplementary materials such as videos and animations. Such approach ensures that students remain more interested and quite participative in teaching and learning activities.
</p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Personalised instruction</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            In teaching every student is different and thus we provide individual support. The various teaching techniques that our tutors use depends on the student's ability level and the specific learning difficulties that one may be facing. This personalised approach makes it possible for a student to get the right kind of support that he or she requires based on learning modality.
            </p>

        <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Practical applications</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            In order to develop practical skills, we use examples and solve relevant tasks during the classes for the students. This process helps in making the learning more meaningful and enables the students to see how the concepts are applied in other areas of life.
            </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Regular feedback</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            It is for this reason that we believe feedbacks are important and should be provided to the students on regular basis. The tutors continue to give feedback in the assignments and the assessments given pointing out strengths and areas to work on. This feedback assist students in monitoring their progress within the course and this improves their morale.
            </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Encouraging independent learning</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Another objective is to build up versed, self-motivated learning abilities in the students by making them fully responsible for their study preferences. We give study skills and management skills and manners that assist the students to do their study and time management all by themselves for them to be independent and confident in their study.
In our tutor assisted learning methods that we use at KBM Tutors, we ensure that education is fun and productive. We strive to help students to achieve their academic potential and acquire liking towards learning.
</p>


        </div>
      </div>
      </div>
  	
		</>
	)
}

export default TeachingMethod	