import React,{useState,useEffect} from 'react';
import {Link,useLocation} from 'react-router-dom';
import MetaData from './MetaData.js';
import { Modal, Button } from 'react-bootstrap';

const Formula = () => {

  const location = useLocation();
  
  // Scroll into view based on the URL hash
  useEffect(() => {
  const hash = location.hash;
  if (hash) {
    const element = document.getElementById(hash.substring(1)); // Remove # from hash
    if (element) {
      // Scroll the element into view
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });

      // Adjust for 100px top padding
      const yOffset = -190; // Negative because we want to offset upwards
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
}, [location]);

  const isActive = (id) => location.hash === `#${id}` ? 'active' : '';

  const [isHovered, setIsHovered] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalHeading, setModalHeading] = useState('');

  // Function to show full text when hovered
  const handleMouseEnter = (id) => {
    setIsHovered(id);
  };

  // Function to show shortened text when not hovered
  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  const handleReadMore = (heading,content) => {
    setModalContent(content);
    setModalHeading(heading);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const contentData = {
    'tailored-lessons': `At the heart of the KBM Formula is our commitment to delivering lessons properly tailored to each student's needs. Whether students prepare for the foundational stages of Key Stage 2 or the critical examinations of A-levels, our approach is tailored to their needs. We ensure instruction is customised to fit each student's unique learning style and academic goals.
This personalised approach allows us to identify and address each student's strengths and areas for improvement. It helps develop their confidence, which, in turn, drives them toward achieving their objectives. For example, students preparing for GCSEs receive targeted support that aligns with their exam curriculum. Meanwhile, those approaching 11+ exams benefit from focused practice on the skills required for these assessments.`,
    'flexible-scheduling': `The KBM Formula recognises that students often manage multiple commitments, including extracurricular activities, family responsibilities, etc. Flexible scheduling accommodates their busy lives. Our tutoring sessions are adaptable and offer multiple schedules that fit into each student's routine.
This flexibility primarily benefits students undergoing extensive preparation for exams such as SATs or A-levels. It reduces the stress of strict scheduling and ensures that learning is a stress-free and manageable part of their lives.`,
    'advanced-tracking': `To enhance the effectiveness of our tutoring, we employ cutting-edge tracking systems. These systems monitor and report on each student's progress. These advanced tools provide detailed insights into students' academic performance and allow us to make data-driven adjustments to our teaching strategies.
By continuously assessing progress, we offer targeted interventions and support. This assistance ensures that students receive the guidance they need. Whether studying Key Stage 3 or preparing for A-levels, this support helps them stay on track and achieve their academic goals.`,
    'interactive-learning': `Engagement is a central part of the KBM Formula. Our lessons are interactive and dynamic. They encourage students to participate and engage with the material actively. This approach makes learning more enjoyable and develops understanding.
Our approach also helps students retain key concepts. For students preparing for exams such as GCSEs or 11+, interactive lessons help make complex subjects more accessible. Also, it develops a solid understanding, leading to improved academic performance.`,
    'goal-setting': `An essential part of the KBM Formula is our focus on goal setting. We work closely with students to help them set realistic and achievable academic goals. KBM helps students create a structured roadmap, which gives them a clear sense of direction and purpose.
This method helps them maintain motivation and focus. Whether students aim for success in their SATs, GCSEs, or A-levels, having well-defined goals helps them stay on track and effectively measure their progress.`,
    'study-skills': `Beyond subject-specific knowledge, the KBM Formula strongly focuses on developing essential study skills that students can apply across all academic stages. We teach students necessary techniques such as time management, effective note-taking, and strategic test preparation.
These skills are crucial not only for succeeding in exams like the SATs and 11+ but also for their future academic and professional efforts. Equipping students with these practical tools, we help them build a solid foundation for lifelong learning and success.`,
    'parental-involvement': `We understand that parental support plays a significant role in a student's educational journey. The KBM Formula includes strategies to engage parents actively. We provide parents with the resources and guidance needed to support their child's learning at home.
By developing a collaborative and supportive learning environment, we empower parents. They become effective partners in their child's education, which enhances the overall effectiveness of our tutoring.`,
    'personalised-support': `KBM Tutors recognises that every learner is unique, so we provide personalised support to ensure each student gets the help they need to succeed. By assessing students' strengths and areas for improvement, we tailor our teaching strategies to fit their specific needs. If a student requires extra help, our flexible approach adjusts to their needs. We also focus on building confidence through goal setting and celebrating achievements, motivating students to stay engaged and excel academically.`,
    'teaching-methods': `The process of teaching and learning at KBM Tutors is as constructive as it is creative. We emphasis on the fact that the methods used depend on each learner. We pay much attention to the process of building a positive learning process that will enable the students to perform according to their potential.We include extended instructions that involve the use of teaching aids. This comes in form of group or class discussions, participation in activities as well as supplementary materials such as videos and animations.
Such approach ensures that students remain more interested and quite participative in teaching and learning activities.`,
  };

	return (
		<>
  <MetaData title='KBM Formula'/>
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('../images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">KBM Formula</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


      <div className="untree_co-section pb-0">
        <div className="container"> 
          <div className="row justify-content-center mb-5">
            <div className="col-lg-12 text-center" data-aos="fade-up" data-aos-delay="0">
              <h2 className="line-bottom text-center mb-4">The KBM Formula</h2>
              <p>
                The KBM Formula is the foundational approach that drives our tutoring methodology. We have developed this formula to help students at different academic levels meet their individual needs. Our strategy helps at various levels, including Key Stage 2, Key Stage 3, A-levels, GCSEs, SATs, and 11+ exams.
Our bespoke approach ensures that each student receives personalised support. It helps them succeed in their academic goals and personal development. The KBM Formula consists of several key items, each developed to address different aspects of the learning process.
              </p>
            </div>
          </div>
          <div className="row">

            <div className="col-6 col-sm-6 col-md-6 col-lg-4 feature-wrap" data-aos="fade-up" data-aos-delay="100">
              <div className={`feature ${isActive('tailored-lessons')}`} id="tailored-lessons">
                <span className="uil uil-book-open"></span>
                <h3>Tailored lessons</h3>
                <p>
                  At the heart of the KBM Formula is our commitment to delivering lessons properly tailored to each student's needs...
                  <span onClick={() => handleReadMore('Tailored Lessons',contentData['tailored-lessons'])}>Read More</span>
                </p>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4 feature-wrap" data-aos="fade-up" data-aos-delay="200">
              <div className={`feature ${isActive('flexible-scheduling')}`} id="flexible-scheduling">
                <span className="uil uil-book-alt"></span>
                <h3>Flexible scheduling</h3>
                <p>
                  The KBM Formula recognises that students often manage multiple commitments, including extracurricular activities...
                  <span onClick={() => handleReadMore('Flexible scheduling',contentData['flexible-scheduling'])}>Read More</span>
                </p>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4 feature-wrap" data-aos="fade-up" data-aos-delay="100">
              <div className={`feature ${isActive('advanced-tracking')}`} id="advanced-tracking">
                <span className="uil uil-book-open"></span>
                <h3>Advanced tracking systems</h3>
                <p>
                  To enhance the effectiveness of our tutoring, we employ cutting-edge tracking systems. These systems monitor...
                  <span onClick={() => handleReadMore('Advanced tracking systems',contentData['advanced-tracking'])}>Read More</span>
                </p>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4 feature-wrap" data-aos="fade-up" data-aos-delay="200">
              <div className={`feature ${isActive('interactive-learning')}`} id="interactive-learning">
                <span className="uil uil-book-alt"></span>
                <h3>Interactive learning</h3>
                <p>
                  Engagement is a central part of the KBM Formula. Our lessons are interactive and dynamic. They encourage students...
                  <span onClick={() => handleReadMore('Interactive learning',contentData['interactive-learning'])}>Read More</span>
                </p>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4 feature-wrap" data-aos="fade-up" data-aos-delay="100">
              <div className={`feature ${isActive('goal-setting')}`} id="goal-setting">
                <span className="uil uil-book-open"></span>
                <h3>Goal setting</h3>
                <p>
                  An essential part of the KBM Formula is our focus on goal setting. We work closely with students to help them...
                  <span onClick={() => handleReadMore('Goal setting',contentData['goal-setting'])}>Read More</span>
                </p>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4 feature-wrap" data-aos="fade-up" data-aos-delay="200">
              <div className={`feature ${isActive('parental-involvement')}`} id="parental-involvement">
                <span className="uil uil-book-alt"></span>
                <h3>Parental involvement</h3>
                <p>
                  We understand that parental support plays a significant role in a student's educational journey. The KBM Formula includes...
                  <span onClick={() => handleReadMore('Parental involvement',contentData['parental-involvement'])}>Read More</span>
                </p>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4 feature-wrap" data-aos="fade-up" data-aos-delay="100">
              <div className={`feature ${isActive('study-skills')}`} id="study-skills">
                <span className="uil uil-book-open"></span>
                <h3>Study skills</h3>
                <p>
                  Beyond subject-specific knowledge, the KBM Formula strongly focuses on developing essential study skills that students...
                  <span onClick={() => handleReadMore('Study skills',contentData['study-skills'])}>Read More</span>
                </p>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4 feature-wrap" data-aos="fade-up" data-aos-delay="200">
              <div className={`feature ${isActive('personalised-support')}`} id="personalised-support">
                <span className="uil uil-book-alt"></span>
                <h3>Personalised support</h3>
                <p>
                  KBM Tutors recognises that every learner is unique, so we provide personalised support to ensure each student gets...
                  <span onClick={() => handleReadMore('Personalised support',contentData['personalised-support'])}>Read More</span>
                </p>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4 feature-wrap" data-aos="fade-up" data-aos-delay="200">
              <div className={`feature ${isActive('teaching-methods')}`} id="teaching-methods">
                <span className="uil uil-book-alt"></span>
                <h3>Teaching Methods</h3>
                <p>
                  KBM Tutors recognises that every learner is unique, so we provide personalised support to ensure each student gets...
                  <span onClick={() => handleReadMore('Teaching Methods',contentData['teaching-methods'])}>Read More</span>
                </p>
              </div>
            </div>
            
          </div>
        </div> 
      </div>

      {/* Modal for showing full text */}
      <Modal dialogClassName="customContentModal" show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header >
          <Modal.Title><h5>{modalHeading}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body><p>{modalContent}</p></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
		</>
	)
}

export default Formula